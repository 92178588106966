import { ImageLoaderProps } from "next/image";

const normalizeSrc = (src: string): string => src.startsWith('/') ? src.slice(1) : src;

export default function cloudinaryLoader({ src, width, quality, format }: ImageLoaderProps & { format?: string }): string {
  const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  if (!cloudName) {
    console.error("NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME is not set in the environment");
    return src;
  }
  const params = ['f_auto', 'c_limit', `w_${width}`, `q_${quality || 'auto'}`];
  if (format) {
    params.push(`f_${format}`);
  }
  return `https://res.cloudinary.com/${cloudName}/image/upload/${params.join(',')}/${normalizeSrc(src)}`;
}

export function generateOpenGraphImageUrl(imagePath: string): string {
  const cloudName = process.env.NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME;
  if (!cloudName) {
    console.error("NEXT_PUBLIC_CLOUDINARY_CLOUD_NAME is not set in the environment");
    return imagePath;
  }
  const params = ["f_auto", "c_fill", "g_auto", "w_1200", "h_630", "q_auto"];
  return `https://res.cloudinary.com/${cloudName}/image/upload/${params.join(",")}/${normalizeSrc(imagePath)}`;
}