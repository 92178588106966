'use client';

import Link from 'next/link';
import { Button } from '@/components/ui/button';
import { useState, useEffect } from 'react';

export default function ClientErrorFallback({ error }: { error: Error }) {
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3;

  useEffect(() => {
    const retryTimeout = setTimeout(() => {
      if (retryCount < maxRetries) {
        setRetryCount(prev => prev + 1);
        window.location.reload();
      }
    }, 5000); // Retry after 5 seconds

    return () => clearTimeout(retryTimeout);
  }, [retryCount]);

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-900 text-center px-4">
      <h1 className="text-4xl font-bold text-gray-800 dark:text-gray-200 mb-4">Oops! Something went wrong</h1>
      <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
        {error.message}
      </p>
      <p className="text-lg text-gray-600 dark:text-gray-400 mb-4">
        Retrying... Attempt {retryCount + 1} of {maxRetries + 1}
      </p>
      <div className="space-y-4">
        <h3 className="text-xl font-semibold text-gray-700 dark:text-gray-300">
          Here are some pages you might find helpful:
        </h3>
        <div className="flex flex-wrap justify-center gap-4">
          <Link href="/">
            <Button>Home</Button>
          </Link>
          <Link href="/destinations">
            <Button>Explore Destinations</Button>
          </Link>
          <Link href="/tools">
            <Button>Travel Tools</Button>
          </Link>
        </div>
      </div>
    </div>
  );
}
