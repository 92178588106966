import React, { useState } from 'react'
import { Button } from './ui/button'
import { Textarea } from './ui/textarea'
import { MessageSquare, Smile, Meh, Frown } from 'lucide-react'

type Emotion = 'positive' | 'neutral' | 'negative'

export function FeedbackForm({ onClose }: { onClose: () => void }) {
  const [feedback, setFeedback] = useState('')
  const [emotion, setEmotion] = useState<Emotion | null>(null)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    try {
      const response = await fetch('/api/submit-feedback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ feedback, emotion }),
      })

      if (response.ok) {
        console.log('Feedback submitted successfully')
        onClose()
      } else {
        console.error('Failed to submit feedback')
      }
    } catch (error) {
      console.error('Error submitting feedback:', error)
    }

    setIsSubmitting(false)
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div className="flex flex-col space-y-1.5 text-center sm:text-left">
        <h2 className="text-lg font-semibold leading-none tracking-tight flex items-center gap-1">
          <MessageSquare className="w-5 h-5" />
          Submit Feedback
        </h2>
        <p className="text-sm text-muted-foreground">
          We&apos;re always looking for ways to help you travel smarter. Please let us know what you think about your experience and we&apos;ll use your feedback to improve.
        </p>
      </div>
      <div className="space-y-4">
        <Textarea
          name="feedback"
          placeholder="Give us your feedback..."
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
          required
        />
        <fieldset className="flex gap-3">
          {[
            { value: 'positive', icon: Smile },
            { value: 'neutral', icon: Meh },
            { value: 'negative', icon: Frown },
          ].map(({ value, icon: Icon }) => (
            <label
              key={value}
              className={`outline outline-1 outline-muted bg-muted/50 p-1 rounded-sm cursor-pointer ${
                emotion === value ? 'bg-subaccent/20 outline-foreground/30' : ''
              }`}
            >
              <input
                type="radio"
                value={value}
                name="emotion"
                checked={emotion === value}
                onChange={() => setEmotion(value as Emotion)}
                className="opacity-0 absolute -z-10"
                required
              />
              <Icon className="w-5 h-5" />
            </label>
          ))}
        </fieldset>
      </div>
      <Button type="submit" className="w-full">
        Submit
      </Button>
    </form>
  )
}