'use client';

import { useState, useEffect } from 'react';
import NextImage, { ImageProps } from "next/image";
import cloudinaryLoader from '@/lib/utils/cloudinaryLoader';
import Head from 'next/head';

interface CloudinaryImageProps extends Omit<ImageProps, 'loader'> {
  preload?: boolean;
  format?: string;
}

export default function CloudinaryImage({ preload, ...props }: CloudinaryImageProps) {
  const [imgSrc, setImgSrc] = useState(props.src);

  const getNumericWidth = (width: number | `${number}` | undefined): number => {
    if (typeof width === 'number') return width;
    if (typeof width === 'string') return parseInt(width, 10);
    return 1200; // default width
  };

  useEffect(() => {
    if (preload && typeof props.src === 'string') {
      const link = document.createElement('link');
      link.rel = 'preload';
      link.as = 'image';
      link.href = cloudinaryLoader({ src: props.src, width: getNumericWidth(props.width), quality: 75, format: props.format });
      document.head.appendChild(link);
    }
  }, [preload, props.src, props.width, props.format]);

  return (
    <>
      {preload && (
        <Head>
          <link
            rel="preload"
            as="image"
            href={cloudinaryLoader({ src: props.src as string, width: getNumericWidth(props.width), quality: 75, format: props.format })}
          />
        </Head>
      )}
      <NextImage
        {...props}
        src={imgSrc}
        loader={({ src, width, quality }) => cloudinaryLoader({ src, width, quality, format: props.format })}
        onError={() => {
          console.error('Image failed to load:', imgSrc);
          setImgSrc('/images/placeholder-image.jpg');
        }}
      />
    </>
  );
}